<template>
    <div class="engbill">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
            <div></div>
        </div>
        <div class="title">
            {{ $t('lang.流通激励') }}
        </div>
        <div class="receivebox" v-loading="loading">
            <div class="box">
                <img src="@/assets/img/house_img4.png" alt="">
                <div class="num">
                    <div class="num_num">{{ parseInt(walletinfo.claimed) || 0 }}</div>
                    <div class="num_title">{{ $t('lang.已领取') }}</div>

                </div>
                <div class="num">
                    <div class="num_num">{{ parseInt(walletinfo.usable) || 0 }}</div>
                    <div class="num_title">{{ $t('lang.待领取') }}</div>

                </div>
                <div>
                    <div style="height: 10px;"></div>
                    <div class="button" style="margin-top: 10px;" @click="claimCoin">
                        {{ $t('lang.领取') }}
                    </div>
                </div>

            </div>

        </div>

        <div class="functional_region" id="myElementId">
            <div class="region">
                <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
                    :class="index == regionIndex ? 'special' : ''" style="margin-right: 26px;">
                    <span>{{ $t(item.name) }}</span>
                </div>
            </div>
        </div>
        <div class="list" v-if="regionIndex == 0">
            <div class="li first">
                <div>{{ $t('lang.o136') }}</div>
                <div>{{ $t('lang.销毁') }}</div>
                <div>{{ $t('lang.获得') }}</div>
            </div>
            <van-list v-model="ensurelist.loading" :finished="ensurelist.finished" :finished-text="`${$t('lang.d145')}`"
                @load="onLoad">
                <div class="li" v-for="(item, index) in ensurelist.list" :key="index">
                    <div style="color: #000000;">{{ item.created_at }}</div>
                    <div style="color: #999999;">{{ item.burn_amount }}</div>
                    <div style="color: #F8931F;">{{ item.reward_amount }}</div>
                </div>
            </van-list>

        </div>
        <div class="list" v-else>
            <div class="li first">
                <div>{{ $t('lang.o136') }}</div>
                <div>{{ $t('lang.数量') }}</div>
                <div>{{ $t('lang.类型') }}</div>
            </div>
            <van-list v-model="recordlist.loading" :finished="recordlist.finished" :finished-text="`${$t('lang.d145')}`"
                @load="onLoad">
                <div class="li" v-for="(item, index) in recordlist.list" :key="index">
                    <div style="color: #000000;">{{ item.created_at }}</div>
                    <div style="color: #999999;">{{ item.usable_change }}</div>
                    <div style="color: #F8931F;">{{ item.type_txt }}</div>
                </div>
            </van-list>

        </div>
    </div>
</template>

<script>
import { mining_api, web3_api } from '@/api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
    data() {
        return {
            functionOption: [{ name: 'lang.铸币账单' }, { name: 'lang.流通账单' }],
            regionIndex: 0,
            loading: false,
            recordlist: {
                loading: false,
                finished: false,
                list: [],
                info: {
                    page: 1,
                    page_size: 10,
                }

            },
            ensurelist: {
                loading: false,
                finished: false,
                list: [],
                info: {
                    page: 1,
                    page_size: 10,
                }

            },
            walletinfo: {},
            claimCoinLoading: false
        }
    },
    components: {

    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    created() {
        this.init()
    },
    mounted() {

    },
    methods: {
        switchovers(index) {
            this.regionIndex = index;

        },
        toprouter() {
            this.$router.go(-1);

        },

        init() {

            if (this.account) {
                this.regionIndex = 0
                this.recordlist = {
                    loading: false,
                    finished: false,
                    list: [],
                    info: {
                        page: 1,
                        page_size: 10,
                    }

                }
                this.ensurelist = {
                    loading: false,
                    finished: false,
                    list: [],
                    info: {
                        page: 1,
                        page_size: 10,
                    }

                },
                    this.mininguserensurelist(),
                    this.miningwalletrecordlist(),
                    this.miningwalletinfo()
            }

        },
        onLoad() {
            if (this.account) {
                this.mininguserensurelist()
            }

        },
        onLoadrecord() {
            if (this.account) {
                this.miningwalletrecordlist()
            }


        },
        async claimCoin() {
            if (this.claimCoinLoading) return;
            let sign = null
            this.claimCoinLoading = true
            try {
                sign = await web3_api.signMessage('Mining Reward Claim', this.account)
            } catch (error) {
                this.$toast(this.$t('lang.签名失败'))
                this.claimCoinLoading = false
                return;
            }
            let data = {
                signature: sign
            }
            mining_api.mininguserclaim(data, this.account).then(res => {
                this.claimCoinLoading = false
                if (res.code == 200) {
                    this.init()
                }
                this.$toast(res.msg)
            })
        },
        miningwalletinfo() {
            this.loading = true
            mining_api.miningwalletinfo(this.account).then(res => {
                this.loading = false
                if (res.code == 200) {
                    this.walletinfo = res.data
                }
            }).catch(err => {
                this.loading = false
            })
        },
        mininguserensurelist() {
            mining_api.mininguserensurelist(this.ensurelist.info, this.account).then(res => {

                if (res.data.list != null || res.data.list.length > 0) {
                    if (this.ensurelist.info.page == 1) {
                        this.ensurelist.list = res.data.list
                    } else {
                        this.ensurelist.list = this.ensurelist.list.concat(res.data.list)
                    }
                    if (res.data.list.length < this.ensurelist.info.page_size) {
                        this.ensurelist.finished = true
                    } else {
                        this.ensurelist.info.page++
                    }
                    this.ensurelist.loading = false
                }

            })
        },
        miningwalletrecordlist() {
            mining_api.miningwalletrecordlist(this.recordlist.info, this.account).then(res => {

                if (res.data.list != null || res.data.list.length > 0) {
                    if (this.recordlist.info.page == 1) {
                        this.recordlist.list = res.data.list
                    } else {
                        this.recordlist.list = this.recordlist.list.concat(res.data.list)
                    }
                    if (res.data.list.length < this.recordlist.info.page_size) {
                        this.recordlist.finished = true
                    } else {
                        this.recordlist.info.page++
                    }
                    this.recordlist.loading = false
                }

            })
        },

    },
}

</script>
<style scoped lang='less'>
.functional_region {

    .region {
        display: flex;

        align-items: center;
        color: rgba(96, 96, 96, 1);
        font-size: 16px;
        margin-right: 26px;

        .special {

            font-size: 18px;
            font-weight: bold;
            color: rgba(0, 0, 0, 1);
        }
    }
}

.engbill {
    padding: 20px;
    padding-top: 52px;
    padding-bottom: 44px;
    background: #F7F7F7;
}

.receivebox {
    margin-bottom: 28px;
    flex-wrap: wrap;
    padding: 8px 11px;
    width: 100%;
    opacity: 1;
    border-radius: 14px;
    background: #FFFFFF;

    .box {
        padding: 0 16px;
        padding-left: 0;
        height: 107px;
        background-image: url('~@/assets/img/hasmter_box2_bg.png');
        /* 不规则背景图片 */
        background-size: 100% 100%;
        background-position: top;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    img {
        width: 81px;
        height: 81px;
    }

    .button {

        padding: 5px 12px;
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-weight: 500;
        color: #F8931F;
    }

    .num {
        .num_title {
            margin-top: 5px;
            font-size: 13px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
        }

        .num_num {
            font-size: 20px;
            font-weight: 500;
            color: #FFFFFF;
        }
    }
}

.title {
    font-size: 18px;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 12px;
}

.recruit_title {
    color: var(rgba(255, 255, 255, 1));
    padding: 20px 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list {
    margin-top: 29px;
    padding: 15px 20px;
    width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    border-radius: 16px;
    background: #FFFFFF;
    border: 7px solid #F8931F;

    .first {
        color: #999999;
        margin-bottom: 20px;
    }

    .li {
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        font-size: 14px;

        &>div {
            width: calc(100% / 3);
            text-align: center;
        }

        &>div:first-child {
            text-align: left;
        }

        &>div:last-child {
            text-align: right;
        }
    }
}
</style>